import React from "react";
import {
    BotMesssage,
    UserMessage
} from "./messages";

const Message = ({
    answer,
    question,
    hideToolbar,
    from,
    message,
    design,
    isAppearance,
    botInfo,
    voiceLanguage,
    voicePlugin,
    isTtsActive,
    setIsTtsActive,
    showTypingIndicator,
    pushToActivitiesHistory,
    conversationID,
    setIncomingActivity,
    itemToAskAbout
}) => {

    return (
        <div
            style={{
                width: "100%",
                marginBottom: "20px",
                display: "flex",
                justifyContent: from === "bot" ? "flex-start" : "flex-end",
                alignItems: "center"

            }}
        >
            {
                from === "bot" && Boolean(message) ?
                    <BotMesssage
                         answer={answer}
                        question={question}
                        hideToolbar={hideToolbar}
                        message={message}
                        design={design}
                        isAppearance={isAppearance}
                        from={from}
                        botInfo={botInfo}
                        voiceLanguage={voiceLanguage}
                        voicePlugin={voicePlugin}
                        isTtsActive={isTtsActive}
                        setIsTtsActive={setIsTtsActive}
                        showTypingIndicator={showTypingIndicator}
                        pushToActivitiesHistory={pushToActivitiesHistory}
                        conversationID={conversationID}
                        setIncomingActivity={setIncomingActivity}
                        itemToAskAbout={itemToAskAbout}
                    /> : null
            }
            {
                from === "user" ?
                    <UserMessage
                        message={message}
                        design={design}
                        isAppearance={isAppearance}
                    /> : null
            }
        </div>
    );
}


export default Message;