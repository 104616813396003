
import { color } from "highcharts";
import React, { useState } from "react";
import "../styles/dataPrivacy.css"
import "../styles/leadFormMenu.css";
import { createOne } from "../../../Apis/lead.api"

const DataPrivacy = ({
  cardData,
  setMenuInfo,
  menuInfo,
  design,
  setLoadingScreen,
  botConfig
}) => {
  const [privacyInfo, setLeadInfo] = useState(
    {
      bot_id: menuInfo.bot_id
    }
  );
 

  return (
    <div
      className="searchat-chatbot-menu"
      style={{
        height: "calc(100% - 60px)",
        bottom: "0px",
        zIndex: "222222",
      }}
    >
    
      <div className="formbg-outer" >
        <div style={{textAlign:"center"}}>
          <div className="formbg-inner">
            <div className="container" >
              <div className="card" data-label="Data Privacy Bot">
                <br />
                <div className="card__container" >
                  <br />
                  <p className="card__body">
                    We'd love to chat, but first we need to check what's cool with you.
                  </p>
                  <p className="card__body" >
                  Under GDPR, we need your consent for use of any personal info you might give us,
                  </p>
                  <p className="card__body">
                  like your name and email address, for example, Here's what we do with it:
                  </p>
                  <br />
                  <div style={{display:"inline-block",  textAlign: "justify"}}>
                   <li> We store it,  so we can pick up where we left off if we talk later.</li>
                   <li> We might send you emails to follow up on our chat here.            </li>
                   <li> We might send you emails with our latest content and promotions.   </li>
                   </div>
                   <br />
                   <br />
                  <h4 className="card__body">Is this okay with you?</h4>
                  <br/>
                  <div className="inner">
                    <form id="stripe-login" >
                        <button 
                         style={{border:`4px solid ${design?.headerColor}`,background:"white"
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                            setMenuInfo(undefined);
                            setLoadingScreen(false);
                        }}
                        > Yes</button>
                    </form>
                  </div>
                  <div className="inner" >
                    <form  >
                        <button className="btn"
                       style={{border:`4px solid ${design?.headerColor}`,background:"white",
                       }}
                       onClick={(e) => {
                        e.preventDefault();
                        setMenuInfo(undefined);
                      }}> No </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataPrivacy;