import React, { useState, useRef, useEffect } from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import { eventDispatcher, generatetRandomId } from "../../../Scripts";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import "../styles/riyadhevent.css";
import "../styles/ItemCard.css";
import { isArray } from "highcharts";

const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const RiyadhEventCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity,
    conversationID,
    setIsTtsActive,
    voicePlugin
}) => {
    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBl8jqw_9sE9qQv0SHmfhGHqnTGtaGA_u4"
    });
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState();
    const [zoom, setZoom] = useState(16);
    const [showMarker, setShowMarker] = useState(true);

    const containerStyle = {
        width: '100%',
        height: '230px'
    };
    useEffect(() => {
        setCenter({
            lat: parseFloat(cardData.lat),
            lng: parseFloat(cardData.long)
        });
    }, [])

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        // setMap(map);
        window.google.maps.event.addListener(map, 'click', function (event) {

            setCenter({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            });
            setShowMarker(false);
            setTimeout(() => setShowMarker(true), 200);
        });
    }, []);


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    return (
        <div
            className="standard-card"
            style={{
                borderRadius: design?.cardBorderRadius
            }}

        >

            <div className="standard-card-img-cover">
               


            {
                Boolean(cardData.priceTo) || Boolean(cardData.priceFrom) ?
                <span className="product-price" style={{ background: design?.cardThemeColor, color: design?.cardButtonFontColor }} >                
                    {
                        Boolean(cardData.priceTo) ?
                            <b >{Boolean(cardData.priceFrom) ? `${cardData.priceFrom}-${cardData.priceTo}` : cardData.priceTo} SAR</b> :<b >{cardData.priceFrom} SAR</b>
                    }
                    &nbsp;
                    &nbsp;
                    <i className="fa-solid fa-ticket"></i>
                  </span>:null
            }


             {
                 cardData.aAge?.toLowerCase() === "all" ||  cardData.aAge ==="" ? null:   <span
                 className="age-badge"
             
           
                 style = {{ 
                   background: design?.cardButtonFontColor , 
                   color:  design?.cardThemeColor ,
                   border:  `2px solid ${design?.cardThemeColor }`,
                   boxShadow: "1px"
                
                 }}        
               >
                   {cardData.aAge}
                     </span>
             }
          {
              cardData.removeRibbon ? null :
              <>
               {
                        Boolean(cardData.expired) ?
                        <div className="ribbon ribbon-top-right"><span>{cardData.isAr ? "منتهية" :"Expired"} </span></div> :null
                    }

{
                        !Boolean(cardData.expired) && Boolean(cardData.endDate) && Boolean(cardData.startDate) && ((new Date(cardData.startDate).getTime() - new Date().getTime())/(1000 * 60 * 60 * 24)) < 0 ?
                        <div className="ribbon ribbon-top-right" style={{fontSize:"8px !important"}}><span>{cardData.isAr ? `تنتهي بعد ${Math.ceil(Math.abs(new Date(cardData.endDate).getTime() - new Date().getTime())/(1000 * 60 * 60 * 24))} يوم`:`Ends after ${Math.ceil(Math.abs(new Date(cardData.endDate).getTime() - new Date().getTime())/(1000 * 60 * 60 * 24))}`} </span></div> :null
                    }
                    {
                        !Boolean(cardData.expired) && Boolean(cardData.endDate) && Boolean(cardData.startDate) && ((new Date(cardData.startDate) - new Date())/(1000 * 60 * 60 * 24)) > 0 ?
                        <div className="ribbon ribbon-top-right" style={{fontSize:"8px !important"}}><span>{cardData.isAr ? `تبدأ بعد ${Math.ceil(Math.abs(new Date(cardData.startDate) - new Date())/(1000 * 60 * 60 * 24))} يوم`:`Starting after ${Math.ceil(Math.abs(new Date(cardData.endDate).getTime() - new Date().getTime())/(1000 * 60 * 60 * 24))}`} </span></div> :null
                    }
              </>
          }
           

{/* 
                <span className="ticket"  >                
                    {
                        Boolean(cardData.priceTo) ?
                            <b className="ticket-text">{Boolean(cardData.priceFrom) ? `${cardData.priceFrom}-${cardData.priceTo}` : cardData.priceTo} SAR</b> :<b >{cardData.priceFrom} SAR</b>
                    }
                  </span> */}
        



        <img
                    src={cardData?.heroImage}
                    style={{ height: isFixedImageSize ? "200px" : "100%" }}
                />


            </div>


            <div
                className="standard-card-desc"
                style={{ background: design?.cardBackgroundColor,paddingTop:"0px", padding:"10px" }}
            >
              
                <div className="event-info" style={{marginBottom: "10px"}}>
                    <p className="title" style={{ color: design.cardThemeColor }}>{cardData.isAr ? cardData.nameAra : cardData.name}</p>
                    <div className="separator"></div>
  
                </div>
              
                <div style={{display:"grid",gridTemplateColumns:"50% 50%"}}>
                {
                     cardData?.category?.length || cardData?.categoryAra?.length ?
                     <div >
                      <div style={{width:"100%",  textAlign:'center', direction: cardData.isAr ? 'rtl':'ltr',color:design.cardThemeColor,fontWeight:600}}>
                      <i className="fa-solid fa-tags" style={{}}></i>
                      &nbsp;
                        {
                        cardData.isAr ? "الفئات:" :"categories"
                        
                        }
                        
                    
                 </div>
                 <div style={{width:"100%", textAlign:'center'}}>
                 {
                        cardData.isAr ?  
                            cardData?.categoryAra?.map(a => a):
                            cardData?.category?.map(a => a)
                     }
                 </div>
                     </div>
                    :null
                 }
                  <div>
                      <div style={{width:"100%", textAlign:'center', direction: cardData.isAr ? 'rtl':'ltr',color:design.cardThemeColor,fontWeight:600}}>
                      <i className="fa-solid fa-location-dot"></i>
                      &nbsp;
                        {
                        cardData.isAr ? "الموقع:" :"Zone:"
                        
                        }
                         
                          
                    
                 </div>
                 <div style={{width:"100%", textAlign:'center'}}>
                 {
                        cardData.isAr ?  
                            cardData.zoneAra:
                            cardData.zone
                     }
                 </div>
                
                     </div>
                </div>
        
          
               
               
           
                    
                   
                      
                              
                       
                                <p
                                    style={{
                                        marginBottom: "10px",
                                        marginTop: "15px",
                                        textAlign: cardData.isAr ? "right" : "left",
                                        direction:cardData.isAr ? "rtl" : "ltr",
                                        color:  design?.cardFontColor
                                    }}
                                 
                                >
                                    <Button
                                        key={generatetRandomId()}
                                        textColor={design?.cardButtonFontColor}
                                        backgroundColor={design?.cardThemeColor}
                                        label={cardData.isAr ? " الموقع على الخارطة": "Event In Map"}
                                        customStyles={{ marginBottom: "5px", padding:"8px" }}
                                        onclick={() => {
                                            setMenuInfo({
                                                menu_type: "map-menu",
                                                title: cardData.isAr ? cardData.zoneAra: cardData.zone,
                                                lat :  cardData.lat,
                                                lng:  cardData.long
                                            });
                                        }}
                                    />
                                     {
                                        Boolean(cardData.ticket_mix_url) && cardData.eventstatus === 3 && !Boolean(cardData.expired) ? 
                                        <div  >
                                        <Button
                                        key={generatetRandomId()}
                                        textColor={design?.cardButtonFontColor}
                                        backgroundColor={design?.cardThemeColor}
                                        label={cardData.isAr ? "احجز تذكرتك": "Book Your Ticket"}
                                        customStyles={{ marginBottom: "5px", padding:"8px" }}
                                        onclick={() =>   window.open( !cardData.ticket_mix_url?.includes("http") ? `https://${cardData.ticket_mix_url}`:cardData.ticket_mix_url , "_blank")}
                                    />   </div>:null
                                    }
                                      {
                                        Boolean(cardData.bookLink) && cardData.eventstatus === 0 && !Boolean(cardData.expired) ? 
                                        <div  >
                                        <Button
                                        key={generatetRandomId()}
                                        textColor={design?.cardButtonFontColor}
                                        backgroundColor={design?.cardThemeColor}
                                        label={cardData.isAr ? "احجز تذكرتك": "Book Your Ticket"}
                                        customStyles={{ marginBottom: "5px", padding:"8px" }}
                                        onclick={() =>   window.open( cardData.bookLink, "_blank")}
                                    />   </div>:null
                                    }
                                </p>

                        
                                
                                 
                      
                    </div>
           


            </div>
  

    );

}




export default RiyadhEventCard;

