import {
  postinteractedWithBot
} from "../Apis/messaging.api";


const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
export default function postMessagesListner (
    setDesign,
    user_id
) {
  window.addEventListener('message', function (e) {
   if(IsJsonString(e.data)){
       const data = JSON.parse(e.data);
       console.log("data", data)
       if(data.type === "update_appearance"){
        setDesign(data.design)
       }
       if(data.type === "open_widget"){
        postinteractedWithBot({
          user_id
        });
       }
   }
  });

}