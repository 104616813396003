import React from "react";
import Carousel from "../../Carousel";
import Message from "../../Messages";
import TypingIndicator from "../../TypingIndicator";
import SuggestedActions from "../../SuggestedActions";
import { LeadFormMenu } from "../../Menus/menus";
import Chart from "../../Charts";
import DynamicTable from "../../DynamicTable"


const findTZ = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
function convertTZ(date, tzString) {
    return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
            timeZone: tzString,
        })
    );
}

const ChatbotTranscriptWindow = ({
    activitiesHistory,
    typingIndicator,
    showTypingIndicator,
    popFromActivitiesHistory,
    setMenuInfo,
    design,
    windowWidth,
    voicePlugin,
    stopShowingTutorial,
    setStopShowingTutorial,
    isOldUser,
    botConfig,
    botInfo,
    setNotification,
    isAppearance,
    pushToActivitiesHistory,
    clientUrl,
    setIncomingActivity,
    conversationID,
    setIsTtsActive,
    showHeader,
    isTtsActive,
    voiceLanguage,
    user_id
}) => {

   
  
    return (
        <div
            id="searchat-transcript-window"
            style={{
                height: showHeader ? "calc(100% - 160px)" : "calc(100% - 60px)",
                padding: "10px 15px",
                overflow: "auto",
                background: 'white',
                backgroundImage: Boolean(design?.transactionBoxBackground) ? `url(${design?.transactionBoxBackground})` : null,
                backgroundRepeat: Boolean(design?.transactionBoxBackground) ? "no-repeat" : null,
                backgroundSize: Boolean(design?.transactionBoxBackground) ? "100% 100%" : null,
            }}
        >
            <div
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >

{
    showHeader ?
    <div
    style={{
        backgroundColor: "",
        color: design?.iconsColor,
        fontSize: "12px",
        background: design?.headerColor,
        width: "50%",
        margin: "auto",
        padding: "5px",
        borderRadius: "5px",
        marginBottom: "20px"
    }}
>
    {new Date(convertTZ(new Date(), findTZ())).toDateString()}

</div>:null
}
               

            </div>
            {
                activitiesHistory.map(
                    activity =>
                        <div >
                            {
                                activity.type === 'carousel' ?
                                    <Carousel
                                        cards={activity.cards}
                                        showTypingIndicator={showTypingIndicator}
                                        setMenuInfo={setMenuInfo}
                                        design={design}
                                        windowWidth={windowWidth}
                                        voicePlugin={voicePlugin}
                                        stopShowingTutorial={stopShowingTutorial}
                                        setStopShowingTutorial={setStopShowingTutorial}
                                        isOldUser={isOldUser}
                                        botConfig={botConfig}
                                        botInfo={botInfo}
                                        setNotification={setNotification}
                                        isAppearance={isAppearance}
                                        clientUrl={clientUrl}
                                        setIncomingActivity={setIncomingActivity}
                                        conversationID={conversationID}
                                        setIsTtsActive = {setIsTtsActive}
                                        pushToActivitiesHistory = {pushToActivitiesHistory}
                                        user_id = {user_id}
                             
                                    /> :
                                    activity.type === 'message' ?
                                        <Message
                                            answer={activity.text}
                                            question = {activity.question}
                                            hideToolbar = {activity?.hideToolbar}
                                            from={activity.from}
                                            message={activity.toShow || activity.text}
                                            design={design}
                                            isAppearance={isAppearance}
                                            botInfo={botInfo}
                                            voiceLanguage={voiceLanguage}
                                            voicePlugin={voicePlugin}
                                            isTtsActive={isTtsActive}
                                            setIsTtsActive={setIsTtsActive}
                                            showTypingIndicator={showTypingIndicator}
                                          pushToActivitiesHistory={pushToActivitiesHistory}
                                          conversationID={conversationID}
                                          setIncomingActivity={setIncomingActivity}
                                        /> :
                                        activity.type === 'suggested_actions' ?
                                        <SuggestedActions
                                            suggestions={activity.suggestions}
                                            popFromActivitiesHistory={popFromActivitiesHistory}
                                            showTypingIndicator={showTypingIndicator}
                                            setIncomingActivity = {setIncomingActivity}
                                            conversationID={conversationID}
                                            setIsTtsActive={setIsTtsActive}
                                            voicePlugin = {voicePlugin}
                                            design = {design}
                                            botInfo = {botInfo}
                                            isAppearance = {isAppearance}
                                            pushToActivitiesHistory = {pushToActivitiesHistory}
                                            user_id = {user_id}
                                        /> :
                                        activity.type === 'table' ?
                                        <DynamicTable
                                            data={activity.data}
                                            design={design}
                                            mapper = {activity.mapper}
                                        /> :
                                        activity.type === "graph" ?
                                            <Chart
                                                settings={activity.graph_settings}
                                                chartEntities={activity.entities}
                                                design={design}
                                                botInfo={botInfo}
                                            /> : null
                            }

                        </div>
                )
            }
            {
                typingIndicator ?
                    <TypingIndicator
                        design={design}
                    /> : null
            }




        </div>
    );
}

export default ChatbotTranscriptWindow;