import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const LoginMenu = ({
    menuInfo,
    setMenuInfo,
    showTypingIndicator,
    design,
    botInfo
}) => {
    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBl8jqw_9sE9qQv0SHmfhGHqnTGtaGA_u4"
    });
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState();
    const [zoom, setZoom] = useState(16);
    const [showMarker, setShowMarker] = useState(true);

    const containerStyle = {
        width: '100%',
        height: `${window.innerHeight - 245}px`
    };
    useEffect(() => {
        setCenter({
            lat: parseFloat(menuInfo.lat),
            lng: parseFloat(menuInfo.lng)
        });
    }, [])

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        // setMap(map);
        window.google.maps.event.addListener(map, 'click', function (event) {

            setCenter({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            });
            setShowMarker(false);
            setTimeout(() => setShowMarker(true), 200);
        });
    }, []);


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);


    return (
        <div
            className="searchat-chatbot-menu"
            style={{ 
                height: "calc(100% - 165px)",
                background:design?.menuBackgroundColor,
                color: design?.menuFontColor,
             
            }}
        >
         <div style={{ width: "100%", marginBottom: "10px", textAlign: "center"}}>
                <i className="fas fa-times" style={{ float: "right", color: "gray", cursor: "pointer", fontSize: "16px" }}
                    onClick={() => {
                        setMenuInfo(undefined);
                    }}
                ></i>
           
            </div>
         <br/>
         <div style={{width:"100%"}}>
         {
                        isLoaded ?
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={zoom}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                ref={mapRef}
                                id="google-map"
                            >
                                { /* Child components, such as markers, info windows, etc. */}
                                {
                                    showMarker ? <Marker position={center} /> : null
                                }

                            </GoogleMap> : null
                    }
         </div>
       
        </div>
    );
}

export default LoginMenu;