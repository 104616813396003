import React, { useEffect } from "react";
import { eventDispatcher,  generatetRandomId } from "../../Scripts";
import "./style.css";

const SuggestedActions = ({
    // popFromActivitiesHistory,
    suggestions,
    showTypingIndicator,
    design,
    botInfo,
    isAppearance,
    pushToActivitiesHistory,
    setIncomingActivity,
    setIsTtsActive,
    voicePlugin,
    conversationID 
}) => {


    return (
        <div style={{width:"100%", marginBottom:"10px"}}>
            {
                suggestions?.map( 
                    suggestion => 
                    <div
                        key = {generatetRandomId()}
                        className = "searchat-chatbot-suggested-actions"
                        style = {{
                            width:"auto",
                            display:"inline-block",
                            background:design?.suggestedActionsBackground,
                            border:`2px solid ${design?.suggestedActionsBorderColor}`,
                            margin: '1px',
                            borderRadius:"10px",
                            textAlign:"center",
                            color:design?.suggestedActionsFontColor,
                            fontWeight:"500",
                            cursor:"pointer",
                           
                            padding:"5px 10px"
                        }}
                        onClick = {() => {
                            eventDispatcher(
                                JSON.stringify({
                                    user_input: suggestion.value,
                                    bot_id: botInfo.bot_id
                                }),  
                                (activities) => {
                                    activities.map(a => {
                                        setIncomingActivity(a);
                                    });
                                    var text = "";
                                    const text_arr = activities.filter(a => a.type === "message");
                                    text_arr.forEach(a => {
                                        text = text.concat(` ${a.text}`);
                                    });
                                    const event = new Event("dispatchTextToSpeech");
                                    event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                    event.showMuteIcon = () => setIsTtsActive(true);
                                    event.hideMuteIcon = () => setIsTtsActive(false);
                                    event.tts_gender = voicePlugin?.tts_gender;
                                    window.dispatchEvent(event);
                                },
                                conversationID,
                                botInfo.bot_id
                            );                       
                            showTypingIndicator();
                            pushToActivitiesHistory({
                                type: "message",
                                text: suggestion.title,
                                from: "user"
                            });
                            // popFromActivitiesHistory();  
                            if(isAppearance){
                                window.parent.postMessage(JSON.stringify({
                                    type: "open_appearance_blocks",
                                    block: "suggestedButtonTable"
                                }), '*');
                            }              
                        }}                    
                    >
                    {suggestion.title}
                    </div>
                )
            }            
        </div>
    )
}


export default SuggestedActions;