import { serverURL } from "../constants";
import {
  createPostJSON,
} from "../helper/customs";

const dest = "/lead";

const createOne = (info) => {
  return fetch(serverURL.concat(dest), createPostJSON(info))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};



export { 
    createOne
};
