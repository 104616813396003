import React, { useRef, useState } from 'react';
import { eventDispatcher, sttDispatcher } from "../../../Scripts";
import "../styles/botMessage.css";
import showdown from 'showdown';
import SuggestedActionsShare from '../../SuggestedActionsShare';


const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const markdownToHtmlConverter = value => {
    var converter = new showdown.Converter();
    return converter.makeHtml(value);
};

const BotMesssage = ({
    answer,
    question,
    hideToolbar,
    message,
    design,
    isAppearance,
    botInfo,
    voiceLanguage,
    voicePlugin,
    isTtsActive,
    setIsTtsActive,
    showTypingIndicator,
    pushToActivitiesHistory,
    conversationID,
    setIncomingActivity,
}) => {

    const [myMessage, setMessage] = useState();
    const [isSearchActive, setSearchActive] = useState(false);


    const suggested_actions =
        [{ title: "Facebook", icon: "fa-brands fa-facebook" },
        { title: "Whatsapp", icon: "fa-brands fa-whatsapp" },
        { title: "Email", icon: "fa-solid fa-envelope" }];

    const [showSuggestedActions, setShowSuggestedAction] = useState(false)

    const CopytoClipBoard = (message) => {
        navigator.clipboard.writeText(message);

    }
    return (
        <div
            style={{ width: "100%", display: "flex", alignItems: "center" }}
            onClick={() => {
                if (isAppearance) {
                    window.parent.postMessage(JSON.stringify({
                        type: "open_appearance_blocks",
                        block: "botMessageTable"
                    }), '*');
                }
            }}
        >
            {myMessage ? (
                <div
                    className="import-message"
                    style={{
                        position: "absolute",
                        right: "8.5px",
                        top: "10%",
                        color: message.color,
                        border: `3px solid  ${myMessage.color}`,
                        boxShadow: `0 4px 20px -2px ${myMessage.color}`,
                        padding: "10px",
                        textAlign: "center",
                        borderRadius: "10px"
                    }}
                >
                    {myMessage.text}
                </div>
            ) : null}

            <div style={{ width: "50px", display: "inline-block" }}>
                <img src={design?.botAvatar} style={{ height: "40px", width: "40px", borderRadius: "40px" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                <div
                    id="text"
                    className="searchat-bot-message-bubble"
                    style={{
                        display: "inline-block",
                        background: design?.botBubbleBackground,
                        borderRadius: design?.botBorderRadius,
                        borderTopLeftRadius: design?.botBubbleNub ? "0px" : design?.botBorderRadius,
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                        color: design?.botFontColor,
                        fontSize: "16px",
                        textAlign: ar_regex.test(message) ? "right" : "left",
                        direction: ar_regex.test(message) ? "rtl" : "ltr",
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            message.replace(/null/g,"غير متوفر"),
                    }}
                >
                </div>
                {
                    !hideToolbar ? <div
                        className="social-menu"
                        style=
                        {{
                            display: "inline-block",
                            borderRadius: design?.botBorderRadius,
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px",
                            color: design?.botFontColor,
                        }}
                    >
                        <ul >
                            {!isTtsActive ? (
                                <li onClick={() => {
                                    const event = new Event("dispatchTextToSpeech");
                                    const text = answer.replace(new RegExp("<[^>]*>", "g"), "");
                                    event.showMuteIcon = () => setIsTtsActive(true);
                                    event.hideMuteIcon = () => setIsTtsActive(false);
                                    event.data = text
                                    event.tts_gender = voicePlugin?.tts_gender;
                                    window.dispatchEvent(event);
                                }}
                                >
                                    <a target="blank"><i className="fa fa-volume"></i></a></li>
                            ) : (

                                <li
                                    style={{ left: "10px", top: "12px" }}
                                    onClick={() => {
                                        setIsTtsActive(false);
                                        const event = new Event("dispatchTextToSpeechStop");

                                        window.dispatchEvent(event);
                                    }}
                                >
                                    <a target="blank"><i className="fa fa-volume-mute"></i></a>
                                </li>)}
                            <li id="copy" ><a
                                onClick={() => {
                                    setMessage({
                                        text: "Copied!",
                                        color: "#447540",
                                    });
                                    setTimeout(() => {
                                        setMessage(undefined);
                                    }, 3000);

                                    CopytoClipBoard(
                                        message
                                    );
                                }}
                                target="blank"><i className="fa fa-clipboard"></i></a>
                                <span className="copiedtext" aria-hidden="true">Copied</span></li>

                            {!isSearchActive ? (
                                <li onClick={() => {
                                    setShowSuggestedAction(true);
                                    setSearchActive(true);
                                }}>
                                    <a target="blank"><i className="fa fa-share-nodes"></i></a></li>
                            ) :
                                <li onClick={() => {
                                    setShowSuggestedAction(false);
                                    setSearchActive(false);
                                }}>
                                    <a target="blank"><i className="fa fa-share-nodes"></i></a></li>
                            }
                        </ul>
                    </div> : null}
                <div style={{ marginTop: "10px" }}>
                    {showSuggestedActions ?
                        <SuggestedActionsShare
                            question={question}
                            message={message}
                            suggestions={suggested_actions}
                            showTypingIndicator={showTypingIndicator}
                            design={design}
                            botInfo={botInfo}
                            isAppearance={isAppearance}
                            pushToActivitiesHistory={pushToActivitiesHistory}
                            setIncomingActivity={setIncomingActivity}
                            setIsTtsActive={setIsTtsActive}
                            voicePlugin={voicePlugin}
                            conversationID={conversationID}
                        /> : null
                    }

                </div>
            </div>
        </div>
    );
}

export default BotMesssage;