import { sendMessage } from "../Apis/activity.api";



export default function eventDispatcher (
    triggerToSend,
    cb,
    conversationID,
    bot_id,
    user_id 
){
    // console.log("conversationID",conversationID,triggerToSend)
    // window.zE('webWidget', 'chat:send', JSON.parse(triggerToSend).user_input);
    sendMessage({
        text: triggerToSend,
        conversation_id: conversationID,
        bot_id: bot_id,
        user_id : user_id 
    }).then(data => {
        console.log("data sendMessage", data)
        if(data){
            cb(data?.responses); 
        }
        else{
            cb([{
                type:"message",
                from: "bot",
                text: ""
            }]);
        }   
    });
}