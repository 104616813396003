import React, { useState, memo } from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import {
    GoogleMap,
    withGoogleMap,
    withScriptjs,
    DirectionsRenderer
} from 'react-google-maps';
import { withProps, compose, lifecycle } from "recompose";


const MapWithADirectionsRenderer = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyBl8jqw_9sE9qQv0SHmfhGHqnTGtaGA_u4&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `300px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            console.log("cardData", new window.google.maps.LatLng(24.7255553, 47.1027124))
            const DirectionsService = new window.google.maps.DirectionsService();

            DirectionsService.route(
                {
                    origin: new window.google.maps.LatLng(24.7255553, 47.1027124),
                    destination: new window.google.maps.LatLng(25.927501, 49.6692758),
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: result
                        });
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }
    })
)(props => (
    <GoogleMap
        defaultZoom={10}
        defaultCenter={new window.google.maps.LatLng(-37.8136, 144.9631)}
    >
        {props.directions && <DirectionsRenderer directions={props.directions} />}
    </GoogleMap>
));

const SarBuyTicketCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity,
    conversationID,
    setIsTtsActive,
    voicePlugin
}) => {

    const [formData, setFormData] = useState(cardData.settings.initData);

   

    const updateState = (entity, value) => {
        const formDataToUpd = { ...formData };
        formDataToUpd[entity] = value;
        setFormData(formDataToUpd);
    }

    return (
        <div
            className="standard-card"
            style={{
                borderRadius: design?.cardBorderRadius
            }}

        >
            <div
                className="standard-card-heading"
                style={{ textAlign: "center", padding: "5px",background: design?.cardThemeColor, color: design.cardButtonFontColor }}

            >
                البحث عن رحلات
            </div>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "50% 50%",
                    padding: "10px"
                }}
            >
                <div
                    style={{ padding: "10px" }}
                >
                    <select
                        style={{ border: "none", borderRadius: "8px", direction: "rtl", padding: "10px", width: "100%", background: "#E6F1F3" }}
                    >

                        <option> محطة الوصول</option>
                    </select>

                </div>
                <div
                    style={{ padding: "10px" }}
                >
                    <select
                        style={{ border: "none", borderRadius: "8px", direction: "rtl", padding: "10px", width: "100%", background: "#E6F1F3" }}
                    >
                        <option>الرياض</option>
                    </select>

                </div>

            </div>
            <MapWithADirectionsRenderer />
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "50% 50%",

                }}
            >
                <div
                    style={{
                        padding: "10px", display: "grid",
                        gridTemplateColumns: "50% 50%",
                        padding: "10px"
                    }}
                >
                    <div style={{ textAlign: "right" }}>
                        بالغ
                        <div className="number">

                            <span
                                className="minus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >-</span>
                            &nbsp;
                            <input
                                type="number"
                                className="searchat-qty-input"
                                value={formData?.settings?.initData?.adult}
                                style={{ width: "30px" }}
                            />
                            &nbsp;
                            <span
                                className="plus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >+</span>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        شاب
                        <div className="number">

                            <span
                                className="minus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >-</span>
                            &nbsp;
                            <input
                                type="number"
                                className="searchat-qty-input"

                                style={{ width: "30px" }}

                            />
                            &nbsp;
                            <span
                                className="plus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >+</span>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        طفل
                        <div className="number">

                            <span
                                className="minus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >-</span>
                            &nbsp;
                            <input
                                type="number"
                                className="searchat-qty-input"

                                style={{ width: "30px" }}

                            />
                            &nbsp;
                            <span
                                className="plus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >+</span>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        رضيع
                        <div className="number">

                            <span
                                className="minus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >-</span>
                            &nbsp;
                            <input
                                type="number"
                                className="searchat-qty-input"

                                style={{ width: "30px" }}

                            />
                            &nbsp;
                            <span
                                className="plus"
                                style={{ background: design?.headerColor, width: "18px", height: "18px", borderRadius: "18px", padding: '0px', fontSize: "12px", fontWeight: "600" }}

                            >+</span>
                        </div>
                    </div>
                </div>
                <div
                    style={{ padding: "10px", textAlign: "right" }}
                >
                    <div>     <label for="start">الذهاب</label>
                        <input
                            style={{
                                width: "100%",
                                padding: "5px",
                                padding: '10px 12px',
                                marginBottom: '5px',
                                border: '1px solid #e5e5e5',
                                borderBottom: '2px solid #ddd',
                                background: '#f2f2f2',
                                color: '#555',
                                zIndex: 1000000
                            }}


                            type="date"

                        />

                    </div>
                    <div>     <label for="start">العودة</label>
                        <input
                            style={{
                                width: "100%",
                                padding: "5px",
                                padding: '10px 12px',
                                marginBottom: '5px',
                                border: '1px solid #e5e5e5',
                                borderBottom: '2px solid #ddd',
                                background: '#f2f2f2',
                                color: '#555',
                                zIndex: 1000000
                            }}


                            type="date"

                        />

                    </div>

                </div>

            </div>
            <div style={{ width: "100%", padding: "10px" }}>

                <Button
                    textColor={design?.cardButtonFontColor}
                    backgroundColor={design?.cardThemeColor}
                    label="اختيار الرحلة"
                    customStyles={{ marginBottom: "5px" }}
                    onclick={() => {

                    }}
                />
            </div>
        </div>

    );

}


export default memo(SarBuyTicketCard);

