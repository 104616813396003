import React, { useState, useRef, useEffect, memo } from "react";
import "../styles/standardCard.css";
import Button from "../../Button";
import { eventDispatcher, generatetRandomId } from "../../../Scripts";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import "../styles/riyadhevent.css";


const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const MapCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    design,
    botInfo,
    setMenuInfo,
    setIncomingActivity,
    conversationID,
    setIsTtsActive,
    voicePlugin,
    pushToActivitiesHistory
}) => {
    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBl8jqw_9sE9qQv0SHmfhGHqnTGtaGA_u4"
    });
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState();
    const [zoom, setZoom] = useState(16);
    const [showMarker, setShowMarker] = useState(true);

    console.log("cardData",cardData)
    const containerStyle = {
        width: '100%',
        height: '230px'
    };
    useEffect(() => {
        setCenter({
            lat: parseFloat(cardData.lat),
            lng: parseFloat(cardData.lng)
        });
    }, [])

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        // setMap(map);
        window.google.maps.event.addListener(map, 'click', function (event) {
            console.log("event",event)
            setCenter({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            });
            setShowMarker(false);
            setTimeout(() => setShowMarker(true), 200);
        });
    }, []);


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    return (
        <div
            className="standard-card"
            style={{
                borderRadius: design?.cardBorderRadius
            }}

        >
             <div className="standard-card-img-cover">
             {
                    Boolean(cardData?.map_image) ?
                        <img
                            src={cardData?.map_image}
                            style={{ height: isFixedImageSize ? "200px" : "100%" }}
                        /> :
                        null
                }
             </div>
             {
                Boolean(cardData?.heading) ||
                    Boolean(cardData?.subheading) ||
                    cardData?.actions.length ?
                    <div
                        className="standard-card-desc"
                        style ={{ background:design?.cardBackgroundColor}}
                    >
                        {
                            Boolean(cardData?.heading) ?
                                <div
                                    className="standard-card-heading"
                                    style={{
                                        marginBottom: "10px",
                                        textAlign: ar_regex.test(cardData?.heading) ? "right" : "left",
                                        direction:ar_regex.test(cardData?.heading) ? "rtl" : "ltr",
                                        color:  design?.cardFontColor
                                    }}
                                    dangerouslySetInnerHTML ={{__html: cardData?.heading}}
                                >
                                
                                </div>
                                : null
                        }

                        {
                            Boolean(cardData?.subheading) ?
                                <p
                                    style={{
                                        marginBottom: "10px",
                                        textAlign: ar_regex.test(cardData?.subheading) ? "right" : "left",
                                        direction:ar_regex.test(cardData?.subheading) ? "rtl" : "ltr",
                                        color:  design?.cardFontColor
                                    }}
                                    dangerouslySetInnerHTML ={{__html: cardData?.subheading}}
                                >
                                   
                                </p>
                                : null
                        }
                     
                        {
                            cardData?.actions.map(
                                action =>
                                    <Button
                                        key={generatetRandomId()}
                                        textColor={design?.cardButtonFontColor}
                                        backgroundColor={design?.cardThemeColor}
                                        label={action.label}
                                        customStyles={{ marginBottom: "5px" }}
                                        onclick={() => {
                                            console.log("conversationID",conversationID)
                                            if (action.type === "postback") {
                                                pushToActivitiesHistory({
                                                    type: "message",
                                                    text: action.label,
                                                    from: "user",
                                                });
                                                if (typeof action.postback === "object") {
                                                   
                                                    eventDispatcher(
                                                        JSON.stringify({
                                                            user_input: action.postback,
                                                            bot_id: botInfo.bot_id
                                                        }),
                                                        (activities) => {
                                                            activities.map(a => {
                                                                setIncomingActivity(a);
                                                            });
                                                            var text = "";
                                                            const text_arr = activities.filter(a => a.type === "message");
                                                            text_arr.forEach(a => {
                                                                text = text.concat(` ${a.text}`);
                                                            });
                                                            const event = new Event("dispatchTextToSpeech");
                                                            event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                                            event.showMuteIcon = () => setIsTtsActive(true);
                                                            event.hideMuteIcon = () => setIsTtsActive(false);
                                                            event.tts_gender = voicePlugin?.tts_gender;
                                                            window.dispatchEvent(event);
                                                        },
                                                        conversationID,
                                                        botInfo.bot_id
                                                    );
                                                   
                                                }
                                                else {
                                                    eventDispatcher(
                                                        JSON.stringify({
                                                            user_input: action.postback,
                                                            bot_id: botInfo.bot_id
                                                        }),
                                                        (activities) => {
                                                            activities.map(a => {
                                                                setIncomingActivity(a);
                                                            });
                                                            var text = "";
                                                            const text_arr = activities.filter(a => a.type === "message");
                                                            text_arr.forEach(a => {
                                                                text = text.concat(` ${a.text}`);
                                                            });
                                                            const event = new Event("dispatchTextToSpeech");
                                                            event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                                                            event.showMuteIcon = () => setIsTtsActive(true);
                                                            event.hideMuteIcon = () => setIsTtsActive(false);
                                                            event.tts_gender = voicePlugin?.tts_gender;
                                                            window.dispatchEvent(event);
                                                        },
                                                        conversationID,
                                                        botInfo.bot_id
                                                    );
                                                   
                                                }
                                                showTypingIndicator();
                                            }
                                            else {
                                                window.open(action.url, "_blank");
                                            }
                                        }}
                                    />
                            )
                        }

                    </div> : null
            }


        </div>

    );

}




export default memo(MapCard);

