import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./routes";
import Home from "./Pages/Home/index";
import { Helmet } from "react-helmet"

console.log = () => {}

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>ضمان بوت</title>
        <meta name="description" content="مرحبا ٫ انا ضمان بوت  . كيف يمكنني مساعدتك؟" />
        <link rel="icon" href="./NjnDKMpUL5Km2suEUrblVwrPSCnokq0V8lZK38pgKYuikZuKj1ZgnUS3WDndcB.png" />
        <link rel="apple-touch-icon" href="./NjnDKMpUL5Km2suEUrblVwrPSCnokq0V8lZK38pgKYuikZuKj1ZgnUS3WDndcB.png" />
        <meta property="og:title" content="ضمان بوت" />
        <meta property="og:description" content="مرحبا ٫ انا ضمان بوت  . كيف يمكنني مساعدتك؟" />
        <meta property="og:image" content="./public/NjnDKMpUL5Km2suEUrblVwrPSCnokq0V8lZK38pgKYuikZuKj1ZgnUS3WDndcB.png" />
      </Helmet>
      <Router>
        <Switch>
          <Route path={Routes.home} component={Home}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
