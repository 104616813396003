import React from "react";
import "./style.css";
import AdaptiveCard from "../AdaptiveCards";
import { generatetRandomId } from "../../Scripts";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';



SwiperCore.use([
    Navigation, 
    Pagination, 
    Scrollbar, 
    A11y,
    EffectFade,
]);

const Carousel = ({
    cards,
    showTypingIndicator,
    setMenuInfo,
    design ,
    windowWidth,
    voicePlugin,
    stopShowingTutorial ,
    setStopShowingTutorial ,
    isOldUser,
    botConfig,
    botInfo,
    setNotification ,
    isAppearance ,
    clientUrl,
    setIncomingActivity,
    conversationID,
    setIsTtsActive ,
    pushToActivitiesHistory,
    user_id
}) => {
    const getNumberOfSlidesPerView = () => {
        return parseInt(windowWidth/400) > cards.length ? cards.length : parseInt(windowWidth/400) ;
    }
    return (
     
      <div 
        className="searchat-carousel-div"
        onClick = {() => {
            if(isAppearance){
                window.parent.postMessage(JSON.stringify({
                    type: "open_appearance_blocks",
                    block: "botCardTable"
                }), '*');
            }
        }}
      >   
        {
            cards.length >1 ?
            <Swiper
            spaceBetween={1}
            slidesPerView={getNumberOfSlidesPerView() > 0 ? (getNumberOfSlidesPerView() + 0.1): 1.1}
         
            // pagination={{
            //     "clickable": true
            //   }}
            navigation = {cards.length > getNumberOfSlidesPerView()}
       
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
          >
                {
                    cards.map((card, index) =>     
                    <SwiperSlide
                        key={generatetRandomId()}
                    >  
                           
                            <AdaptiveCard
                                cardData={card}
                                showTypingIndicator = {showTypingIndicator}
                                isFixedImageSize = {true}
                                setMenuInfo = {setMenuInfo}
                                design = {design}
                                voicePlugin = {voicePlugin} 
                                card_index = {index}   
                                stopShowingTutorial = {stopShowingTutorial}
                                setStopShowingTutorial = {setStopShowingTutorial} 
                                isOldUser = {isOldUser}
                                botConfig={botConfig}
                                botInfo = {botInfo}
                                setNotification = {setNotification}
                                clientUrl = {clientUrl}
                                setIncomingActivity = {setIncomingActivity}
                                conversationID = {conversationID}
                                setIsTtsActive = {setIsTtsActive}
                                pushToActivitiesHistory = {pushToActivitiesHistory}
                                user_id = {user_id}
                            />  
                    </SwiperSlide>             
                    )
                } 
                </Swiper> :
                 <AdaptiveCard
                 cardData={cards[0]}
                 showTypingIndicator = {showTypingIndicator}
                 isFixedImageSize = {false}
                 setMenuInfo = {setMenuInfo}
                 design = {design}
                 voicePlugin = {voicePlugin}
                 card_index ={0}
                 stopShowingTutorial = {stopShowingTutorial}
                 setStopShowingTutorial = {setStopShowingTutorial}
                 isOldUser = {isOldUser}
                 botConfig={botConfig}
                 botInfo = {botInfo}
                 setNotification = {setNotification}
                 clientUrl = {clientUrl}
                 setIncomingActivity = {setIncomingActivity}
                 conversationID = {conversationID}
                 setIsTtsActive = {setIsTtsActive}
                 pushToActivitiesHistory = {pushToActivitiesHistory}
                 user_id = {user_id}
       
             />

        } 
      
  </div>
 

    );

}


export default Carousel;