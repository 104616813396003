
import React, { useState } from "react";
import "../styles/leadFormMenu.css";
import { createOne } from "../../../Apis/lead.api"

const isEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(String(email).toLowerCase())) {
    return email;
  } else {
    return false;
  }
};

const LeadFormMenu = ({
  menuInfo,
  setMenuInfo,
  design,
  setLoadingScreen,
  botConfig
}) => {

  const [leadInfo, setLeadInfo] = useState(
    {
      lead_email: "",
      lead_phone: "",
      bot_id: menuInfo.bot_id
    }
  );
  const [countryCode, setCountryCode] = useState("");

  return (
    <div
      className="searchat-chatbot-menu"
      style={{
        height: "calc(100% - 60px)",
        bottom: "0px",
        zIndex: "222222",
        background: design?.menuBackgroundColor,
        overflow: 'auto',

      }}
    >
      <div className="formbg-outer">
        <div className="formbg">
          <div className="formbg-inner padding-horizontal--48">
            <form id="stripe-login">
              {
                menuInfo?.check_email ?
                  <div className="field padding-bottom--24">
                    <label for="email" style={{ marginBottom: "10px" }}>Email</label>
                    {
                      !isEmail(leadInfo.lead_email) && Boolean(leadInfo.lead_email) ?
                        <label style={{ color: "red", fontSize: "14px" }}> invalid email</label> : null
                    }
                    <input
                      type="email"
                      name="email"
                      style={{
                        width: "100%",
                        padding: "5px",
                        padding: '10px 12px',
                        marginBottom: '5px',
                        border: '1px solid #e5e5e5',
                        borderBottom: '2px solid #ddd',
                        background: '#f2f2f2',
                        color: '#555'
                      }}
                      onChange={(event) => setLeadInfo({
                        ...leadInfo,
                        lead_email: event.target.value
                      })}
                      value={leadInfo.lead_email}
                    />
                  </div> : null
              }

              {
                menuInfo?.check_phone ?
                  <div className="field padding-bottom--24">

                    <label for="phone_number" style={{ marginBottom: "10px" }}>Phone Number</label>

                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="phone"
                      style={{
                        width: "100%",
                        padding: "5px",
                        padding: '10px 12px',
                        marginBottom: '5px',
                        border: '1px solid #e5e5e5',
                        borderBottom: '2px solid #ddd',
                        background: '#f2f2f2',
                        color: '#555'
                      }}
                      onChange={(event) => setLeadInfo({
                        ...leadInfo,
                        lead_phone: event.target.value
                      })}
                      value={leadInfo.lead_phone}
                    />
                  </div> : null
              }
              
              <div className="field padding-bottom--24" align="center">
                <button
                  disabled={
                    !Boolean(leadInfo?.lead_email) &&
                    !Boolean(leadInfo?.lead_phone) &&
                    !isEmail(leadInfo.lead_email)
                  }
                  style={{
                    backgroundColor:
                      Boolean(leadInfo?.lead_email) &&
                        Boolean(leadInfo?.lead_phone) &&
                        isEmail(leadInfo.lead_email) ?
                        design?.menuButtonBackgroundColor : "gray",
                    cursor: 'pointer',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.04), 0 6px 6px rgba(0, 0, 0, 0.12)',
                    width: '50%',
                    borderRadius: "10px"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setLoadingScreen(true);
                    createOne({
                      ...leadInfo,
                      lead_city: botConfig?.city || "",
                      lead_country: botConfig?.country || "",
                    }).then(() => {
                      setMenuInfo(undefined);
                      setLoadingScreen(false);
                    });
                  }}
                >Continue</button>
              </div>
              <div className="field padding-bottom--24" align="center">
                {
                  menuInfo?.is_optional ?
                    <button
                     
                      style={{
                        backgroundColor:

                          design?.menuButtonBackgroundColor,
                        cursor: 'pointer',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.04), 0 6px 6px rgba(0, 0, 0, 0.12)',
                        width: '50%',
                        borderRadius: "10px"
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuInfo(undefined);
                      }}
                    >Skip</button> : null
                }
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  );
}

export default LeadFormMenu;