import React, { useState, useEffect } from "react";
import "../styles/ItemCard.css";
import { eventDispatcher } from "../../../Scripts";
// import ReactCardFlip from 'react-card-flip';
import { Steps } from 'intro.js-react';
import CryptoJS from 'crypto-js';




const ar_regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
const ItemCard = ({
  cardData,
  isFixedImageSize,
  setMenuInfo,
  design,
  showTypingIndicator,
  voicePlugin,
  card_index,
  stopShowingTutorial,
  setStopShowingTutorial,
  isOldUser,
  botConfig,
  botInfo,
  setNotification,
  clientUrl,
  conversationID ,
  setIsTtsActive,
  setIncomingActivity
}) => {

  // const [isFilpped, setIsFipped] = useState(false);
  const [isItemCardTtsActive, setIsItemCardTtsActive] = useState(false);
  const [showSecondImage, setShowSecondImage] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [itemTutorialSteps, setItemTutorialSteps] = useState([]);


  console.log("ItemCard cardData",cardData)

  useEffect(() => {
    const steps = [];
    if (Boolean(cardData?.item_price)) {
      steps.push({
        element: '#product-price',
        intro: botConfig?.itemCardTourItemPriceMessage,
        position: 'right'
      });
    }
    if (Boolean(cardData?.item_offer)) {
      steps.push({
        element: '#offer-ribbon',
        intro: 'offer',
        position: 'right'
      });
    }
    if (cardData?.actions?.length) {
      steps.push({
        element: '#item-buy',
        intro: 'add Item to cart',
        position: 'right'
      });
    }
    if (voicePlugin?.tts) {
      steps.push({
        element: '#tts-item',
        intro: botConfig?.itemCardTourTtsMessage,
        position: 'right'
      });
    }
    if (cardData?.item_features?.length) {
      steps.push({
        element: '#show-item-desc',
        intro: botConfig?.itemCardTourDescriptionMessage,
        position: 'right'
      });
      steps.push({
        element: '#ask-about-item',
        intro: botConfig?.itemCardTourAskAboutSpecsMessage,
        position: 'right'
      });
    }

    steps.push({
      element: '#share-item',
      intro: botConfig?.itemCardTourShareMessage,
      position: 'right',
    });

    // setItemTutorialSteps([...steps]);
    // if (card_index === 0) {
    //   setTimeout(() => setShowTutorial(!stopShowingTutorial && !isOldUser), 1000);
    // }
  }, []);


  return (
    <div

      className="standard-card"
      style={{ borderRadius: design?.cardBorderRadius}}
    >
     
      <Steps
        enabled={showTutorial}
        steps={itemTutorialSteps}
        initialStep={0}
        onExit={() => {
          setShowTutorial(false);
          setStopShowingTutorial(true);
        }}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Prev',
        }}

      />

    
        <div >

          <div className="standard-card-img-cover"  >
            {
              cardData.item_offer && Boolean(cardData?.item_price) ?
                <>
                  <span className="product-price" style={{ background: design?.cardThemeColor }} id={card_index === 0 ? "product-price" : null}>
                    <del> <b>
                      {cardData?.item_price?.toFixed(2)} 
                    {cardData?.currency}</b> </del>
                    <br />
                    <b>{cardData?.sale?.toFixed(2)} {cardData?.currency}</b>
                  </span>
                </> :
                Boolean(cardData?.item_price) ?
                  <span className="product-price" style={{ background: design?.cardThemeColor, color: design?.cardButtonFontColor }} id={card_index === 0 ? "product-price" : null}>
                    <b>{cardData?.item_price} {cardData?.currency}</b>
                  </span> : null
            }


            {
            cardData?.item_offer?.offer_type === "Percentage" &  cardData?.item_offer?.offer_percentage ?
                <div className="ribbon ribbon-top-right" id={card_index === 0 ? "offer-ribbon" : null}><span>{cardData.item_offer.offer_percentage * 100}% off</span></div> : null
            }

            {
              cardData?.actions.map(
                action =>
                  <span
                    id={card_index === 0 ? "item-buy" : null}
                    className="add-to-cart-icon"
                    title = "Add To Cart"
                    onClick={(e) => {
                      e.preventDefault();
                      if (action.type !== 'link') {
                        setMenuInfo({ ...cardData, menu_type: "item_info" })
                      }
                      else {
                     
                        if(Boolean(clientUrl)){
                          // Encrypt
                          let encJson = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify({
                            "item_id": cardData.item_id,
                            "url": clientUrl.split("?")[0]  
                          })), "incubator").toString()
                          let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
                         

          
                       
                          window.parent.location.href = 
                          action.url?.includes("?") ?
                          action.url.concat(`&conversion=${encData}`):
                          action.url.concat(`?conversion=${encData}`);                    
                        }
                        else {
                          window.parent.location.href = action.url;
                        }           
                      }
                    }}
                    style={{ background: design?.cardThemeColor, color: design?.cardButtonFontColor }}
                  >
                    <i className="fas fa-shopping-cart">

                    </i>
                  </span>
              )
            }

            {
              voicePlugin.tts ?
                <span
                  className="rate-product-icon"
                  id={card_index === 0 ? "tts-item" : null}
                  title = "Read Card Info"
                  onClick={() => {
                    if (!isItemCardTtsActive) {
                      const event = new Event("dispatchTextToSpeech");
                      if (cardData?.item_offer) {
                        event.data = `${cardData.item_title} item has an offer, the final cost is ${cardData?.sale?.toFixed(2)} ${cardData?.currency}`;
                      }
                      else if(Boolean(cardData?.item_price)) {
                        event.data = `${cardData.item_title} will cost you ${cardData.item_price} ${cardData.currency}`;
                      }
                      else {
                        event.data = `${cardData.item_title}`;
                      }

                      event.showMuteIcon = () => setIsItemCardTtsActive(true);
                      event.hideMuteIcon = () => setIsItemCardTtsActive(false);
                      event.tts_gender = voicePlugin?.tts_gender;
                      
                      window.dispatchEvent(event);
                    }
                    else {
                      const event = new Event("dispatchTextToSpeechStop");
                      window.dispatchEvent(event);
                      setIsItemCardTtsActive(false);
                    }
                  }}
                  style={{ background: design?.cardThemeColor, color: design?.cardButtonFontColor }}
                >
                  {
                    isItemCardTtsActive ?
                      <i className="fas fa-volume-mute"></i>
                      :
                      <i className="fas fa-volume-down"></i>
                  }

                </span> : null

            }



            {/* <span className="fav-product-icon">
              <i className="fas fa-heart"></i>
            </span> */}

            <span
              className="share-product-icon"
              id="share-item"
              title = "Share"
              onClick={() => {
                setMenuInfo({
                  menu_type: "share_item",
                  itemToShare: cardData.item_title,
                  item_id :  cardData.item_id
                });
              }}
              style = {{ 
                background: design?.cardThemeColor, 
                color: design?.cardButtonFontColor 
              }}        
            >
              <i className="fa fa-share-alt"></i>
            </span>


            <span
              className="desc-item-icon"
              title = "Item Info"
              onClick={() => {
                setMenuInfo({
                  menu_type: "item_page",
                  ...cardData
                });
              }}
              style={{ background: design?.cardThemeColor, color: design?.cardButtonFontColor }}
              id="show-item-desc"
            >
              <i className="fas fa-exclamation"></i>
            </span>
            {
              cardData.item_features?.length ?
                <span
                  className="ask-item-icon"
                  title = "Ask About This Item"
              
                  onClick={() => {
                    eventDispatcher(
                      JSON.stringify({
                        user_input: `__item_qna${cardData.item_id}`,
                        bot_id: botInfo.bot_id
                      }),
                      (activities) => {
                        activities.map(a => {
                            setIncomingActivity(a);
                        });
                        var text = "";
                        const text_arr = activities.filter(a => a.type === "message");
                        text_arr.forEach(a => {
                            text = text.concat(` ${a.text}`);
                        });
                        const event = new Event("dispatchTextToSpeech");
                        event.data = text.replace(new RegExp('<[^>]*>', 'g'), '');
                        event.showMuteIcon = () => setIsTtsActive(true);
                        event.hideMuteIcon = () => setIsTtsActive(false);
                        event.tts_gender = voicePlugin?.tts_gender;
                        window.dispatchEvent(event);
                    },
                      conversationID,
                      botInfo.bot_id
                    );
                    showTypingIndicator();
                  }}
                  style={{ background: design?.cardThemeColor, color: design?.cardButtonFontColor  }}
                  id="ask-about-item"
                >
                  Ask
                </span> : null
            }



            {
              cardData?.item_icons?.length ?
                <img
                  alt=""
                  src={showSecondImage ? cardData?.item_icons[1] : cardData?.item_icons[0]}
                  style={{ height: isFixedImageSize ? "200px" : "100%", maxHeight: "200px" }}

                  onMouseMove={() => {
                    if (cardData?.item_icons?.length > 1) {
                      setShowSecondImage(true);
                    }

                  }}
                  onMouseLeave={() => {
                    setShowSecondImage(false);
                  }}
                /> : <img
                  src={design?.botIcon}
                  style={{ height: isFixedImageSize ? "200px" : "100%", maxHeight: "200px" }}
                  alt=""

                />
            }



          </div>
          <div
            className="standard-card-desc"
            style={{ background:design?.cardBackgroundColor}}
          >
            {
              Boolean(cardData?.item_title) ?
                <div
                  className="standard-card-heading"

                  style={{
                    marginBottom: "10px",
                    textAlign: ar_regex.test(cardData?.item_title) ? "right" : "left"
                  }}
                >
                  {cardData?.item_title}
                </div>
                : null
            }



          </div>
        </div>
        

 

    </div>
  )

}


export default ItemCard;