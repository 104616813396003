import React from "react";

import {
    StandardCard,
    ItemCard,
    ReservationCard,
    LoadMore,
    ItemSpecsCard,
    BusinessCard,
    Sa3iTrackingCard,
    FormCard,
    SarBuyTicketCard,
    SarReservation,
    SarQr,
    RiyadhEventCard,
    MapCard,
    FullCalendarCard,
    GeneralLoadMoreCard,
    SscCard
} from "./Cards/index";


const AdaptiveCard = ({
    cardData,
    showTypingIndicator,
    isFixedImageSize,
    setMenuInfo,
    design,
    voicePlugin,
    stopShowingTutorial ,
    setStopShowingTutorial ,
    card_index,
    isOldUser,
    botConfig,
    botInfo ,
    setNotification ,
    clientUrl,
    setIncomingActivity,
    conversationID ,
    setIsTtsActive ,
    pushToActivitiesHistory,
    user_id 
}) => {
    console.log("cardData",cardData)
    return (
        <>
            {
                cardData.card_type === 'standard' ?
                    <StandardCard
                        cardData={cardData}
                        showTypingIndicator={showTypingIndicator}
                        isFixedImageSize={isFixedImageSize}
                        design={design}
                        botInfo = {botInfo}
                        setMenuInfo = {setMenuInfo}
                        setIncomingActivity = {setIncomingActivity}
                        conversationID = {conversationID}
                        setIsTtsActive = {setIsTtsActive}
                        voicePlugin = {voicePlugin}
                        pushToActivitiesHistory = {pushToActivitiesHistory}
                        user_id = {user_id}
                    /> : null
            }
            {
                cardData.card_type === 'ssc_card' ?
                    <SscCard
                        cardData={cardData}
                        showTypingIndicator={showTypingIndicator}
                        isFixedImageSize={isFixedImageSize}
                        design={design}
                        botInfo = {botInfo}
                        setMenuInfo = {setMenuInfo}
                        setIncomingActivity = {setIncomingActivity}
                        conversationID = {conversationID}
                        setIsTtsActive = {setIsTtsActive}
                        voicePlugin = {voicePlugin}
                        pushToActivitiesHistory = {pushToActivitiesHistory}
                    /> : null
            }
             {
                cardData.card_type === 'map' ?
                    <MapCard
                        cardData={cardData}
                        showTypingIndicator={showTypingIndicator}
                        isFixedImageSize={isFixedImageSize}
                        design={design}
                        botInfo = {botInfo}
                        setMenuInfo = {setMenuInfo}
                        setIncomingActivity = {setIncomingActivity}
                        conversationID = {conversationID}
                        setIsTtsActive = {setIsTtsActive}
                        voicePlugin = {voicePlugin}
                        pushToActivitiesHistory= {pushToActivitiesHistory}
                    /> : null
            }
             {
                cardData.card_type === 'riyadh-event' ?
                    <RiyadhEventCard
                        cardData={cardData}
                        showTypingIndicator={showTypingIndicator}
                        isFixedImageSize={isFixedImageSize}
                        design={design}
                        botInfo = {botInfo}
                        setMenuInfo = {setMenuInfo}
                        setIncomingActivity = {setIncomingActivity}
                        conversationID = {conversationID}
                        setIsTtsActive = {setIsTtsActive}
                        voicePlugin = {voicePlugin}
                    /> : null
            }
            {
                cardData.card_type === 'item' ?
                    <ItemCard
                        cardData={cardData}
                        isFixedImageSize={isFixedImageSize}
                        setMenuInfo={setMenuInfo}
                        design = {design}
                        showTypingIndicator = {showTypingIndicator}
                        voicePlugin = {voicePlugin}  
                        stopShowingTutorial = {stopShowingTutorial}
                        setStopShowingTutorial = {setStopShowingTutorial}
                        card_index = { card_index}  
                        isOldUser = {isOldUser}
                        botConfig = {botConfig}
                        botInfo = {botInfo}
                        setNotification = {setNotification}
                        clientUrl = {clientUrl}
                        conversationID = {conversationID}
                        setIsTtsActive = {setIsTtsActive}
                        setIncomingActivity = {setIncomingActivity}
                        user_id = {user_id}
                        
                    /> : null
            }
            {
                cardData.card_type === 'reservation' ?
                    <ReservationCard
                        cardData={cardData}
                        isFixedImageSize={isFixedImageSize}
                        design={design}
                        botInfo = {botInfo}
                    /> : null
            }
             {
                cardData.card_type === 'load_more' ?
                    <LoadMore
                        cardData={cardData}
                        design={design}
                        showTypingIndicator = {showTypingIndicator}
                        botInfo = {botInfo}
                        setIsTtsActive = {setIsTtsActive}
                        conversationID = {conversationID}
                        setIncomingActivity = {setIncomingActivity}
                        voicePlugin = {voicePlugin}      user_id = {user_id}
                    /> : null
             }
               {
                cardData.card_type === 'general_load_more' ?
                    <GeneralLoadMoreCard
                        cardData={cardData}
                        design={design}
                        showTypingIndicator = {showTypingIndicator}
                        botInfo = {botInfo}
                        setIsTtsActive = {setIsTtsActive}
                        conversationID = {conversationID}
                        setIncomingActivity = {setIncomingActivity}
                        voicePlugin = {voicePlugin}
                        user_id = {user_id}
                    /> : null
             }

             {
                cardData.card_type === 'item_specs' ?
                    <ItemSpecsCard
                        cardData={cardData}
                        design={design}
                    /> : null
             }

            {
                cardData.card_type === 'business_card' ?
                    <BusinessCard
                        cardData={cardData}
                        design={design}
                    /> : null
             }

             {
                 cardData.card_type === 'sa3i_result' ?
                 <Sa3iTrackingCard
                        cardData={cardData}
                        design={design}
                 />:null
             }

             {
                cardData.card_type === 'form_card' ?
                <FormCard
                    cardData={cardData}
                    showTypingIndicator={showTypingIndicator}
                    isFixedImageSize={isFixedImageSize}
                    design={design}
                    botInfo = {botInfo}
                    setMenuInfo = {setMenuInfo}
                    setIncomingActivity = {setIncomingActivity}
                    conversationID = {conversationID}
                    setIsTtsActive = {setIsTtsActive}
                    voicePlugin = {voicePlugin}
                    user_id = {user_id}
                />:null
             }
             {
                cardData.card_type === 'SAR_BUY_TICKET' ?
                <SarBuyTicketCard
                    cardData={cardData}
                    showTypingIndicator={showTypingIndicator}
                    isFixedImageSize={isFixedImageSize}
                    design={design}
                    botInfo = {botInfo}
                    setMenuInfo = {setMenuInfo}
                    setIncomingActivity = {setIncomingActivity}
                    conversationID = {conversationID}
                    setIsTtsActive = {setIsTtsActive}
                    voicePlugin = {voicePlugin}
                    user_id = {user_id}
                />:null
             }
              {
                cardData.card_type === 'SAR_RESERVATION' ?
                <SarReservation
                    cardData={cardData}
                    showTypingIndicator={showTypingIndicator}
                    isFixedImageSize={isFixedImageSize}
                    design={design}
                    botInfo = {botInfo}
                    setMenuInfo = {setMenuInfo}
                    setIncomingActivity = {setIncomingActivity}
                    conversationID = {conversationID}
                    setIsTtsActive = {setIsTtsActive}
                    voicePlugin = {voicePlugin}
                    user_id = {user_id}
                />:null
             }
              {
                cardData.card_type === 'SAR_QR' ?
                <SarQr
                    cardData={cardData}
                    showTypingIndicator={showTypingIndicator}
                    isFixedImageSize={isFixedImageSize}
                    design={design}
                    botInfo = {botInfo}
                    setMenuInfo = {setMenuInfo}
                    setIncomingActivity = {setIncomingActivity}
                    conversationID = {conversationID}
                    setIsTtsActive = {setIsTtsActive}
                    voicePlugin = {voicePlugin}
                    user_id = {user_id}
                />:null
             }
             {
                 cardData.card_type === "calendar" ?
                 <FullCalendarCard
                    cardData={cardData}
                    showTypingIndicator={showTypingIndicator}
                    isFixedImageSize={isFixedImageSize}
                    design={design}
                    botInfo = {botInfo}
                    setMenuInfo = {setMenuInfo}
                    setIncomingActivity = {setIncomingActivity}
                    conversationID = {conversationID}
                    setIsTtsActive = {setIsTtsActive}
                    voicePlugin = {voicePlugin}
                    user_id = {user_id}
                />:null
             }
            
        </>
    )
}

export default AdaptiveCard;