import React, { useEffect, useState } from "react";
import {
  ChatbotHeader,
  ChatbotFooter,
  ChatbotTranscriptWindow,
} from "./subComponents";
import {
  incomingActivitiesHandler,
  getDateFromQueryString,
  postMessagesListner,
  eventDispatcher,
  // connectToZendesk
} from "../../Scripts";
import Menu from "../Menus";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import "./style.scss";
import { getTriggersByName } from "../../Apis/trigger.api";
import { getIpAddress } from "../../Apis/location.api";
import NotificationMessages from "../NotificationMessages";
import { v4 as uuidv4 } from "uuid";
import { 
   osVersion,
   osName, 
   browserName, 
   browserVersion, 
   engineName, 
   engineVersion, 
   deviceType , 
   mobileModel, 
   mobileVendor ,
} from 'react-device-detect';

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};




const Chatbot = () => {
  const [tutorialSteps, setTutorialSteps] = useState();
  const [incomingActivity, setIncomingActivity] = useState();
  const [activitiesHistory, setActivitiesHistory] = useState([]);
  const [typingIndicator, setTypingIndicator] = useState(true);
  const [menuInfo, setMenuInfo] = useState();
  const [design, setDesign] = useState();
  const [botInfo, setBotInfo] = useState();
  const [productTriggers, setProductTriggers] = useState([]);
  const [weatherTriggers, setWeatherTriggers] = useState([]);
  const [addressTriggers, setAddressTriggers] = useState([]);
  const [mainMenuTriggers, setMainMenuTriggers] = useState([]);
  const [privacyPolicyTriggers, setPrivacyPolicyTriggers] = useState([]);
  const [termsTriggers, setTermsTriggers] = useState([]);
  const [tradingPolicyTriggers, setTradingPolicyTriggers] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [voiceLanguage, setVoiceLanguage] = useState("ar-EG");
  const [isTtsActive, setIsTtsActive] = useState(false);
  const [voicePlugin, setVoicePlugin] = useState({
    tts: false,
    stt: false,
  });
  const [botID, setBotID] = useState(undefined);
  const [botName, setBotName] = useState(undefined);

  const [showTutorial, setShowTutorial] = useState(false);
  const [stopShowingTutorial, setStopShowingTutorial] = useState(false);
  const [isOldUser, setIsOldUser] = useState(false);
  const [botConfig, setBotConfig] = useState();
  const [notification, setNotification] = useState();
  const [clientUrl, setClientUrl] = useState();
  const [isAppearance, setIsAppearance] = useState();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [conversationID, setConvesationId] = useState();
  const [showHeader, setShowHeader] = useState(false);
  const [livechat, setLivechat] = useState(false);
  const [userID, setUserID] = useState(undefined);
  const [deviceInfo, setDeviceInfo ] = useState(undefined);


  const getAllMenuTriggers = (bot_id) => {
    getTriggersByName(`__mainmenu__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setMainMenuTriggers([...data]) : null
    );
    getTriggersByName(`__cart__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setProductTriggers([...data]) : null
    );

    getTriggersByName(`__Weather__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setWeatherTriggers([...data]) : null
    );

    getTriggersByName(`__address__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setAddressTriggers([...data]) : null
    );

    getTriggersByName(`__privacy__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setPrivacyPolicyTriggers([...data]) : null
    );

    getTriggersByName(`__terms__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setTermsTriggers([...data]) : null
    );

    getTriggersByName(`__trading__trigger__${bot_id}`).then((data) =>
      Boolean(data) ? setTradingPolicyTriggers([...data]) : null
    );
  };

  const scrollTranscriptWindow = () => {
    const transcriptWindow = document.getElementById(
      "searchat-transcript-window"
    );
    setTimeout(() => {
      transcriptWindow?.scroll({
        top: transcriptWindow.scrollHeight,
        behavior: "smooth",
      });
    }, 500);
  };

  const showTypingIndicator = () => {
    setTypingIndicator(true);
    scrollTranscriptWindow();
  };

  const pushToActivitiesHistory = (newActivity) => {
    var currentActivitiesHistory = [...activitiesHistory];
    // currentActivitiesHistory = currentActivitiesHistory.filter(a => a.type !== "suggested_actions");
    currentActivitiesHistory.push(newActivity);
    setActivitiesHistory([...currentActivitiesHistory]);
    scrollTranscriptWindow();
  };

  const popFromActivitiesHistory = () => {
    const currentActivitiesHistory = [...activitiesHistory];
    currentActivitiesHistory.pop();
    setActivitiesHistory([...currentActivitiesHistory]);
  };


const getDeviceInfo = (
  country, 
  city,
  did_comeback,
  host,
  host_route
) => {
  console.log("Boolean(did_comeback) ",did_comeback )
  const deviceInfo = {
    os: osName,
    browser: browserName,
    os_version:osVersion,
    browser_version: browserVersion, 
    engine: engineName, 
    engine_version:engineVersion, 
    mobile_model: mobileModel, 
    mobile_vendor: mobileVendor ,
    device:  deviceType,
    country,
    city,
    did_comeback: did_comeback === "true" ? 1 : 0,
    host,
    host_route
  }

  setDeviceInfo(deviceInfo);
}
  useEffect(async () => {
    const {
      trigger,
      old_user,
      client_url,
      is_appearance,
      conversion,
      hide_header,
      national_id,
      did_comeback,
      host,
      host_route
    } = getDateFromQueryString();

 

    const bot_id = 403;


    const user_id = uuidv4();

    // localStorage.setItem("outside", user_id);

    setUserID(user_id);

    // setMenuInfo({
    //     menu_type: "data_privacy",
    // });

    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    search_params.delete("bot_id");
    search_params.delete("trigger");
    search_params.delete("old_user");
    search_params.delete("client_url");
    search_params.delete("is_appearance");
    search_params.delete("conversion");
    search_params.delete("hide_header");
    search_params.delete("national_id");

    setShowHeader(hide_header !== "true");
    const conversation_id = uuidv4();
    setConvesationId(conversation_id);

    // const SessionDate = localStorage.getItem("SessionDate")
    // if (SessionDate) {
    //     const current = new Date();
    //     var hours = Math.abs(current - new Date(JSON.parse(SessionDate)?.date)) / 36e5;
    //     if (hours > 24) {
    //         await createUniqueUser({
    //             bot_id
    //         });
    //         localStorage.setItem("SessionDate", JSON.stringify({
    //             date: new Date()
    //         }));
    //     }
    // }
    // else {
    //     await createUniqueUser({
    //         bot_id
    //     });
    //     localStorage.setItem("SessionDate", JSON.stringify({
    //         date: new Date()
    //     }));
    // }

    // if (conversion && conversion !== "null") {
    //     // Decrypt

    //     let decData = CryptoJS.enc.Base64.parse(conversion).toString(CryptoJS.enc.Utf8);
    //     let bytes = CryptoJS.AES.decrypt(decData, "incubator").toString(CryptoJS.enc.Utf8);

    //     const isValid = IsJsonString(bytes);

    //     if (isValid) {
    //         var decryptedData = JSON.parse(JSON.parse(bytes));
    //         createOneConversion({
    //             "bot_id": bot_id,
    //             "item_id": decryptedData?.item_id,
    //             "url": decryptedData?.url
    //         });
    //     }
    // }

    setIsAppearance(is_appearance);
    setClientUrl(client_url);

    if (bot_id) {
      setBotID(bot_id);
      // getAllMenuTriggers(bot_id);
      eventDispatcher(
        JSON.stringify({
          user_input: "GET_ONE_BOT",
          bot_id: bot_id,
          national_id
        }),
        async (activities) => {
          const data = activities[0];
          setBotName(data.bot_name);
          if (Boolean(data)) {
            document.documentElement.style.setProperty(
              "--color-scroll",
              data?.headerColor
            );
            setDesign(data);
            window.parent.postMessage(
              JSON.stringify({
                type: "setDesign",
                data: {
                  ...data,
                  user_id,
                  conversation_id,
                  bot_id
                },
              }),
              "*"
            );
            setLoadingScreen(false);
            setBotInfo(data);
            setVoiceLanguage(data.language === "en" ? "en-US" : "ar-EG");
            activities.slice(1, activities.length).map((a) => {
              console.log("a", a);
              setIncomingActivity(a);
            });
            var text = "";
            const text_arr = activities.filter((a) => a.type === "message");
            text_arr.forEach((a) => {
              text = text.concat(` ${a.text}`);
            });
            const event = new Event("dispatchTextToSpeech");
            text = text.replace(new RegExp("<[^>]*>", "g"), "");
            event.data = text;
            event.showMuteIcon = () => setIsTtsActive(true);
            event.hideMuteIcon = () => setIsTtsActive(false);
            event.tts_gender = voicePlugin?.tts_gender;
            window.dispatchEvent(event);
            const ip_data = await getIpAddress();
            eventDispatcher(
              JSON.stringify({
                user_input: {
                  text: "SET_LOCATION",
                  ip_address: ip_data?.ip,
                },
                bot_id: bot_id,
              }),
              (activities) => {
                console.log("activities",activities)
                const locationData = activities[0];
                
                getDeviceInfo(locationData.country_name,locationData.city,did_comeback, host, host_route);
              },
              conversation_id,
              bot_id,
              user_id
            );
            console.log(" eventDispatcher trigger", trigger, bot_id)
            if (Boolean(trigger) && trigger !== "null") {
              setIncomingActivity(
                {
                  from: "user",
                  text: trigger,
                  type: "message"
                }
              )
              showTypingIndicator();
              eventDispatcher(
                JSON.stringify({
                  user_input: trigger,
                  bot_id: bot_id,
                }),

                async (activities) => {
                  activities.map((a) => {
                    console.log("a", a);
                    setIncomingActivity(a);
                  });
                  var text = "";
                  const text_arr = activities.filter(
                    (a) => a.type === "message"
                  );
                  text_arr.forEach((a) => {
                    text = text.concat(` ${a.text}`);
                  });
                  const event = new Event("dispatchTextToSpeech");
                  text = text.replace(new RegExp("<[^>]*>", "g"), "");

                  event.data = text;
                  event.showMuteIcon = () => setIsTtsActive(true);
                  event.hideMuteIcon = () => setIsTtsActive(false);
                  event.tts_gender = voicePlugin?.tts_gender;
                  window.dispatchEvent(event);
                },
                conversation_id,
                bot_id,
                user_id

              );
            }
          }
        },
        conversation_id,
        bot_id,
        user_id

      );

      eventDispatcher(
        JSON.stringify({
          user_input: "GET_ONE_LEAD_PLUGIN",
          bot_id: bot_id,
        }),
        async (activities) => {
          const data = activities[0];
          if (Boolean(data?.check_email) && Boolean(data?.check_phone)) {
            setMenuInfo({
              menu_type: "lead_form",
              ...data,
            });
          }
        },
        conversation_id,
        bot_id,
        user_id

      );

      eventDispatcher(
        JSON.stringify({
          user_input: "GET_ONE_VOICE_PLUGIN",
          bot_id: bot_id,
        }),
        async (activities) => {
          const data = activities[0];
          setVoicePlugin({
            tts: data?.voice_active && data?.tts_active,
            stt: data?.voice_active && data?.stt_active,
            tts_gender: data?.tts_gender,
            show_stt_text: data?.show_stt_text,
          });


        },
        conversation_id,
        bot_id,
        user_id

      );


    }
    postMessagesListner(
      (data) => setDesign({ ...data }),
      user_id
    );
    window.onresize = function () {
      setWindowWidth(window.innerWidth);
    };
  }, []);

  useEffect(() => {
    if (Boolean(incomingActivity) && incomingActivity?.type === "graph") {
      pushToActivitiesHistory(incomingActivity);
    }
    if (
      Boolean(incomingActivity) &&
      incomingActivity?.type === "livechat_start"
    ) {
      setLivechat(true);

      setTypingIndicator(false);
    }
    if (
      Boolean(incomingActivity) &&
      incomingActivity?.type !== "menu" &&
      incomingActivity?.type !== "bot_config"
    ) {
      pushToActivitiesHistory(incomingActivity);
      setTypingIndicator(false);
    }
    if (incomingActivity?.type === "menu") {
      setTypingIndicator(false);
      setMenuInfo(incomingActivity);
    }
    if (incomingActivity?.type === "bot_config") {
      const steps = [];
      setBotConfig(incomingActivity.config);
      if (design?.voiceIcon && voicePlugin.stt) {
        steps.push(
          {
            element: "#mic",
            intro: incomingActivity?.config?.mainTourMicMessage,
            position: "right",
          },
          {
            element: "#button-6",
            intro: incomingActivity?.config?.mainTourToggleMicLanguageMessage,
            position: "right",
          }
        );
      }
      steps.push(
        {
          element: "#input-user-message",
          intro: incomingActivity?.config?.mainTourTextInputMessage,
          position: "right",
        },
        {
          element: "#mainmenuicon",
          intro: incomingActivity?.config?.mainTourMainMenuMessage,
          position: "right",
        },
        {
          element: "#minimize",
          intro: incomingActivity?.config?.mainTourCloseChatbotWindowMessage,
          position: "right",
        },
        {
          element: "#resizeChatbotWindow",
          intro: incomingActivity?.config?.mainTourResizeChatbotWindowMessage,
          position: "right",
        }
      );
      setTutorialSteps([...steps]);
    }
  }, [incomingActivity]);

  return (
    <div
      className="searchat-chatbot-window"
      style={{
        position: "fixed",
        bottom: "0px",
        height: "100%",
        backgroundColor: "white",
        borderRadius: design?.headerFooterBorderRadius,
        right: "0px",
        border: "1px solid #e3e6f0",
      }}
    >
      {loadingScreen ? (
        <div className="container">
          <div className="circle circle-1"></div>
          <div className="circle circle-2"></div>
          <div className="circle circle-3"></div>
          <div className="circle circle-4"></div>
          <div className="circle circle-5"></div>
        </div>
      ) : (
        <>
          {" "}
          <NotificationMessages
            notification={notification}
            setNotification={setNotification}
          />
          {tutorialSteps && showTutorial ? (
            <Steps
              enabled={showTutorial}
              steps={tutorialSteps}
              initialStep={0}
              onExit={() => setShowTutorial(false)}
              options={{
                nextLabel: "Next",
                prevLabel: "Prev",
              }}
            />
          ) : null}
          {showHeader ? (
            <ChatbotHeader
              design={design}
              botInfo={botInfo}
              isAppearance={isAppearance}
            />
          ) : null}
          {/* <NotificationMessages
        /> */}
          <ChatbotTranscriptWindow
            design={design}
            activitiesHistory={activitiesHistory}
            typingIndicator={typingIndicator}
            showTypingIndicator={showTypingIndicator}
            popFromActivitiesHistory={popFromActivitiesHistory}
            pushToActivitiesHistory={pushToActivitiesHistory}
            setMenuInfo={setMenuInfo}
            windowWidth={windowWidth}
            voicePlugin={voicePlugin}
            stopShowingTutorial={stopShowingTutorial}
            setStopShowingTutorial={setStopShowingTutorial}
            isOldUser={isOldUser}
            botConfig={botConfig}
            botInfo={botInfo}
            setNotification={setNotification}
            isAppearance={isAppearance}
            clientUrl={clientUrl}
            setIncomingActivity={setIncomingActivity}
            conversationID={conversationID}
            setIsTtsActive={setIsTtsActive}
            showHeader={showHeader}
            voiceLanguage={voiceLanguage}
            user_id={userID}
            isTtsActive={isTtsActive}
          />
          {menuInfo ? (
            <Menu
              design={design}
              menuInfo={menuInfo}
              setMenuInfo={setMenuInfo}
              showTypingIndicator={showTypingIndicator}
              mainMenuTriggers={{
                productTriggers: [...productTriggers],
                weatherTriggers: [...weatherTriggers],
                addressTriggers: [...addressTriggers],
                privacyPolicyTriggers: [...privacyPolicyTriggers],
                termsTriggers: [...termsTriggers],
                tradingPolicyTriggers: [...tradingPolicyTriggers],
                mainMenuTriggers: [...mainMenuTriggers],
              }}
              botInfo={botInfo}
              botConfig={botConfig}
              voiceLanguage={voiceLanguage}
              windowWidth={windowWidth}
              clientUrl={clientUrl}
              setNotification={setNotification}
              setLoadingScreen={setLoadingScreen}
              conversationID={conversationID}
              setIncomingActivity={setIncomingActivity}
              setIsTtsActive={setIsTtsActive}
              voicePlugin={voicePlugin}
            />
          ) : null}
          <ChatbotFooter
            showTypingIndicator={showTypingIndicator}
            pushToActivitiesHistory={pushToActivitiesHistory}
            setMenuInfo={setMenuInfo}
            botInfo={botInfo}
            menuInfo={menuInfo}
            voiceLanguage={voiceLanguage}
            setVoiceLanguage={setVoiceLanguage}
            isTtsActive={isTtsActive}
            setIsTtsActive={setIsTtsActive}
            voicePlugin={voicePlugin}
            design={design}
            activitiesHistory={activitiesHistory}
            setIncomingActivity={setIncomingActivity}
            conversationID={conversationID}
            livechat={livechat}
            showHeader={showHeader}
            bot_id={botID}
            bot_name={botName}
            user_id={userID}
            deviceInfo = {deviceInfo}
            setDeviceInfo = {setDeviceInfo}
          />
        </>
      )}
    </div>
  );
};

export default Chatbot;
