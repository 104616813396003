import React, { useEffect,useState } from "react";
import { eventDispatcher,  generatetRandomId } from "../../Scripts";
import "./style.css";
import {
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    EmailIcon,
    EmailShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TelegramShareButton,
    TelegramIcon,
    FacebookShareButton,
    FacebookIcon
  } from "react-share";


const SuggestedActionsShare = ({
    question,
    message,
    suggestions,
    showTypingIndicator,
    design,
    botInfo,
    isAppearance,
    pushToActivitiesHistory,
    setIncomingActivity,
    setIsTtsActive,
    voicePlugin,
    conversationID 
}) => {

    function strip(message){
        message.replace(new RegExp("<[^>]*>", "g"), "")
        let doc = new DOMParser().parseFromString(message, 'text/html');
        const MyMessage = doc.body.textContent;
        console.log(MyMessage,"My Message")
        return MyMessage ;
     }

    return (
        <div style={{width:"100%", marginBottom:"10px"}}>
                        <div
                        key = {generatetRandomId()}
                        className = "searchat-chatbot-suggested-actions"
                        style = {{
                            width:"auto",
                            display:"inline-block",
                            background:design?.suggestedActionsBackground,
                            border:`2px solid ${design?.suggestedActionsBorderColor}`,
                            margin: '1px',
                            borderRadius:"10px",
                            textAlign:"center",
                            color:design?.suggestedActionsFontColor,
                            fontWeight:"600",
                            cursor:"pointer",
                            fontSize:"10px",
                        }}                  
                    >
                <TelegramShareButton
                    url={encodeURI(`https://ssc.searchat.com?trigger=${question}`)}
                    title={message.replace(new RegExp("<[^>]*>", "g"), "")} 
                    >
                  <TelegramIcon
                   size={"1.2rem"} // You can use rem value instead of numbers
                   round
                    />
                     <br/>
                    Telegram
                </TelegramShareButton>
                    </div>
                    <div
                        key = {generatetRandomId()}
                        className = "searchat-chatbot-suggested-actions"
                        style = {{
                            width:"auto",
                            display:"inline-block",
                            background:design?.suggestedActionsBackground,
                            border:`2px solid ${design?.suggestedActionsBorderColor}`,
                            margin: '1px',
                            borderRadius:"10px",
                            textAlign:"center",
                            color:design?.suggestedActionsFontColor,
                            fontWeight:"600",
                            cursor:"pointer",
                            fontSize:"10px",
                        }}                  
                    >
                <WhatsappShareButton
                 url={encodeURI(`https://ssc.searchat.com?trigger=${question}`)}
                 title={ message.replace(new RegExp("<[^>]*>", "g"), "")}
                >
                  <WhatsappIcon
                   size={"1.2rem"} // You can use rem value instead of numbers
                   round
                    />
                     <br/>
                    Whatsapp
                    </WhatsappShareButton>
                    </div>
                    <div
                        key = {generatetRandomId()}
                        className = "searchat-chatbot-suggested-actions"
                        style = {{
                            width:"auto",
                            display:"inline-block",
                            background:design?.suggestedActionsBackground,
                            border:`2px solid ${design?.suggestedActionsBorderColor}`,
                            margin: '1px',
                            borderRadius:"10px",
                            textAlign:"center",
                            color:design?.suggestedActionsFontColor,
                            fontWeight:"600",
                            cursor:"pointer",
                            fontSize:"10px",
                        }}                  
                    >
                <EmailShareButton 
                    subject ={question.replace(new RegExp("<[^>]*>", "g"), "")}
                    body={message.replace(new RegExp("<[^>]*>", "g"), "")}
                    >
                  <EmailIcon
                   size={"1.2rem"} // You can use rem value instead of numbers
                   round
                    />
                     <br/>
                    Email</EmailShareButton>
                    </div>
        </div>
    )
}


export default SuggestedActionsShare;