import { createPostJSON } from "../helper/customs";
import { accumalateMessage } from "./messaging.api";
const mockURL = "https://bot-designer-server-2.azurewebsites.net/api/ssc/chatbot";

const sendMessage = (data) => {
  // accumalateMessage({
  //   userId:data.user_id
  // });
  return fetch(`${mockURL}`, createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export { sendMessage };
